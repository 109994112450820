<script>
  /**
   * A price breakdown component, used to list the different costs before placing an order. Includes slots for line items and total(s), which are all `BasePriceBreakdownItem` components.
   */
  export default {
    provide() {
      return {
        BasePriceBreakdown: this,
      }
    },
    props: {
      /** Whether the prices of this breakdown are currently being calculated. */
      calculating: {
        type: Boolean,
        default: false,
      },
      /** The size of the text. */
      size: {
        type: String,
        default: 'md',
        validator: (value) => ['sm', 'md'].includes(value),
      },
    },
  }
</script>

<template>
  <div :class="`${size === 'sm' ? 'text-xs' : 'text-base'} leading-tight`">
    <div v-if="$scopedSlots.title" class="mb-5 text-base sm:text-lg font-semibold">
      <!-- @slot Optional title for list. -->
      <slot name="title" />
    </div>
    <dl>
      <!-- @slot The line items. Should contain one or multiple `BasePriceBreakdownItem` components. -->
      <slot />
      <div v-if="$scopedSlots.total" class="mt-2 text-lg font-semibold">
        <!-- @slot The “total” lines. Should contain one or multiple `BasePriceBreakdownItem` components. -->
        <slot name="total" />
      </div>
      <div v-if="$scopedSlots.totalLarge" class="pb-2 text-lg font-semibold">
        <BaseDivider class="my-4" />
        <!-- @slot Larger, header version of "total" lines. Should contain one or multiple `BasePriceBreakdownItem` components. -->
        <slot name="totalLarge" />
      </div>
    </dl>
  </div>
</template>
